// Inspired by https://github.com/zloirock/core-js/blob/master/packages/core-js/modules/es.object.group-by.js

const groupByPolyfill = <K extends PropertyKey, T>(
  items: Iterable<T>,
  keySelector: (item: T, index: number) => K,
): Partial<Record<K, Array<T>>> => {
  let k = 0;
  return Object.values(items).reduce<Partial<Record<K, Array<T>>>>(
    (result, value) => {
      const keyValue = keySelector(value, k++);
      result?.[keyValue] !== undefined
        ? result[keyValue]!.push(value)
        : result[keyValue] = [value];
      return result;
    },
    {},
  );
};

window.Object.groupBy ??= groupByPolyfill;

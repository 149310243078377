/* eslint-disable import/order */

import '../sass/app.scss';

import.meta.glob([
  '../images/**',
]);

import './polyfills';

// Polyfill for Safari < 16.4; see also https://github.com/material-components/material-web/blob/main/docs/support.md
import 'element-internals-polyfill';

import './bootstrap';

import {copyUrl} from './util/copyUrl';
window.copyUrl = copyUrl;

declare global {
  interface Window {
    copyUrl: typeof copyUrl;
  }
}

export const appLocale: Intl.LocalesArgument = 'nl-NL';

const euroFormatter = new Intl.NumberFormat(appLocale, {
  style: 'currency',
  currency: 'EUR',
});

/**
 * Formats float to an amount-string in euros,
 * e.g. 1.23 => '€ 1,23'
 */
export const formatAmount = (amount: number) => euroFormatter.format(amount);

/**
 * Formats integer (in cents) to an amount-string in euros,
 * e.g. 123 => '€ 1,23'
 */
export const formatAmountInCents = (amount: number) => formatAmount(amount / 100);

import {appLocale} from './locale';

const defaultDateFormattingOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'long',
};

const defaultTimeFormattingOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

type TYear = `${number}${number}${number}${number}`;
type TMonth = `${number}${number}`;
type TDay = `${number}${number}`;

type THours = `${number}${number}`;
type TMinutes = `${number}${number}`;
type TSeconds = `${number}${number}`;
type TMilliseconds = `${number}${number}${number}`;

export type TISODate = `${TYear}-${TMonth}-${TDay}`;
export type TISOTime = `${THours}:${TMinutes}:${TSeconds}.${TMilliseconds}`;
export type TISODateTime = `${TISODate}T${TISOTime}Z`;
type DateString = TISODate | TISODateTime;

// Extend the TypeScript native Date interface
declare global {
  interface Date {
    toISOString(): TISODateTime;
  }
}

export enum DateFormatType {
  Default,
  Birthday,
  Long,
  Numeric,
  Year,
}

const getFormatOptions = (formatType: DateFormatType): Intl.DateTimeFormatOptions => {
  switch (formatType) {
    case DateFormatType.Birthday:
    case DateFormatType.Long:
      return {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };
    case DateFormatType.Numeric:
      return {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      };
    case DateFormatType.Year:
      return {
        year: 'numeric',
      };
    default:
      return defaultDateFormattingOptions;
  }
};

const dateStringToDate = (dateString: DateString) => {
  // Assert format starts with YYYY-MM-DD; see also https://tc39.es/ecma262/#sec-date-time-string-format
  if (!dateString.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}/)) {
    throw new Error(`Date ${dateString} has invalid format`);
  }

  return new Date(dateString);
};

export const formatDate = (
  date: Date,
  formatType = DateFormatType.Default,
  customOptions: Intl.DateTimeFormatOptions = {},
) => date.toLocaleDateString(
  appLocale,
  {
    timeZone: 'Europe/Amsterdam', // for a fully specified time string, we will assume the date to be Dutch
    ...getFormatOptions(formatType),
    ...customOptions,
  },
);

// Format a date string of the form YYYY-MM-DD in specified date format type
export const formatDateString = (
  dateString: TISODate,
  formatType = DateFormatType.Default,
  customOptions: Intl.DateTimeFormatOptions = {},
) => formatDate(
  dateStringToDate(dateString),
  formatType,
  {
    timeZone: 'UTC', // partial string to date parsing will result in a zero-time UTC-based string
    ...customOptions,
  },
);

// Format a date of the form YYYY-MM-DDTHH:mm:ss.sssZ in specified date format type
export const formatISODateString = (
  dateString: TISODateTime,
  formatType = DateFormatType.Default,
  customOptions: Intl.DateTimeFormatOptions = {},
) => formatDate(dateStringToDate(dateString), formatType, customOptions);

// Format timestring part from a date of the form YYYY-MM-DDTHH:mm:ss.sssZ
export const formatTimeString = (dateString: TISODateTime) => dateStringToDate(dateString).toLocaleTimeString(
  appLocale,
  {
    timeZone: 'Europe/Amsterdam', // for a fully specified time string, we will assume the date to be Dutch
    ...defaultTimeFormattingOptions,
  },
);

export const dateStringGetYear = (dateString: TISODate) => dateStringToDate(dateString)
  .getUTCFullYear();

export const ISODateStringGetYear = (dateString: TISODateTime) =>
  Number(formatDate(dateStringToDate(dateString), DateFormatType.Year));
